import Vue from 'vue'

import numeral from 'numeral'
import { mapActions } from 'vuex'

import { first, each } from 'lodash';
import { v4 as uuidv4 } from "uuid";
import moment from 'moment'

const Validation = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          cloverFbLink: 'https://www.facebook.com/CloverComputerLtd',
          boxbox: {
            fb: 'https://www.facebook.com/Boxboxmo-103761682165890/',
            ig: 'https://www.instagram.com/boxboxmo/'
          },
        }
      },
      methods: {
        ...mapActions({
          setErrors: 'validation/setErrors',
          clearErrors: 'validation/clearErrors',
          fetchWallet: 'wallet/fetch',
        }),
        formatImages(images, max = 6) {
          for (let index = 0; index < max; index++) {
            if (images[index]) {
              images[index]["uuid"] = uuidv4();
              continue;
            }

            images.push({
              uuid: uuidv4(),
            });
          }

          return images;
        },

        formatCategories(categories, prefix = "") {
          const data = [];

          categories.forEach((category) => {
            let name = `${prefix}${category.name}`;
            data.push({
              text: name,
              value: category.id,
            });
            // if (category.children.length > 0) {
            //   data.push(...this.formatCategories(category.children, `${name} > `));
            // }
          });

          return data;
        },

        renameErrorsKey(e, prefix) {
          if (e.response.status !== 422) return;
          const errors = {};
          const _errors = e.response.data.errors;
          const keys = Object.keys(_errors);
          each(keys, (k) => (errors[`${prefix}.${k}`] = _errors[k]));
          this.setErrors(errors);

        },
        getFirstImage(images) {
          return first(images) ?? { url: "/imgs/noimage.jpg" };
        },
        GoBack() {
          this.$router.go(-1)
        },
        setQuery(query) {
          this.$router.push(this.localePath({ query }))
        },
        mapQuery(query) {
          return this.setQuery({ ...this.$route.query, ...query })
        },
        async execute(callback) {
          if (this.parsing) return;
          try {
            this.parsing = true
            await callback()
          } catch (e) { }
          finally {
            this.parsing = false
          }
        },

        exportExcel(dataset, filename, sheet_name = 'UN') {
          const ws = XLSX.utils.aoa_to_sheet(dataset);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, sheet_name);

          // 設置柵位寬度
          const cols = [];
          for (let index = 0; index < 20; index++) {
            cols.push({ wch: 30 })
          }
          ws["!cols"] = cols;

          /* generate file and send to client */
          return XLSX.writeFile(wb, filename);
        },

        async verify() {
          const token = await this.$recaptcha.execute("login");
          this.form["g-recaptcha-response"] = token;
        },

        clearCookies() {
          this.$cookies.remove('invitedby')
          // this.$cookies.removeAll();
        },

        _goSocial(link, target = '_blank') {
          if (this.inapp) {
            return window.flutter_inappwebview.callHandler('launch', {
              url: link
            });
          }
          var anchor = document.createElement('a');
          anchor.href = link;
          anchor.target = target;
          anchor.click();
          anchor.remove();
        },

        saveAppToken() {
          if (!this.inapp || !this.$auth.loggedIn) return;
          if (!_appdata) return;

          this.$axios.post('/dating/devices/tokens', _appdata);
        },

        getRedirectPath() {
          return this.$auth.$storage.getCookie("redirect") ?? "/home";
        },

        toLink(content, opt) {
          let key
          let linkAttributes
          let options
          let pattern
          let value

          options = opt ?? {
            class: 'text-primary',
            target: '_blank',
          }

          pattern = /(^|[\s\n]||<[A-Za-z]*\/?>)((?:https?|ftp):\/\/[\-A-Z0-9+\u0026\u2019@#\/%?=()~_|!:,.]*[\-A-Z0-9+\u0026@#\/%=~()_|])/gi

          linkAttributes = ((() => {
            let results

            results = []
            for (key in options) {
              value = options[key]

              results.push(' ' + key + '="' + value + '"')

            }
            return results
          })()).join('')



          return this.$sanitize(content.replace(pattern, (match, space, url) => {
            var link
            link = '<a href="' + url + '"' + linkAttributes + '>' + url + '</a>'
            return '' + space + link
          }))
        },

        toNumeral(value, fixed) {
          return numeral(value).format(fixed ? fixed : '0,0.00')
        },
        popupPosition() {
          // if is server side rendering, return top
          if (process.server) return "top";
          return window.innerWidth < 768 ? "bottom" : "center";
        },
        refreshManifest() {
          console.log('refreshManifest', this);
        }
      },

      computed: {
        _dev() {
          return process.env.APP_DEBUG == 'true'
        },
        loggedin() {
          return this.$auth.loggedIn
        },
        me() {
          return this.$auth.user
        },

        inapp() {
          return window.flutter_inappwebview;
        },
      },

      filters: {
        number_format(value, fixed) {
          return numeral(value).format(fixed ? fixed : '0,0.00')
        },
        date(value, format) {
          const date = moment(value)

          if (!date.isValid()) return value
          // return humanize(date, format)
          if (format)
            return date.format(format)

          return moment(value).fromNow()

          // humanize
          return moment.duration(date.diff(moment())).humanize()
        },
        
      },
    })
  }
}

Vue.use(Validation)
