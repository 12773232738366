

export const state = () => ({
  profile: {}
})

export const getters = {
  profile: state => state.profile
}

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  }
}

export const actions = {
  getProfile({ commit }, page_id) {
    return this.$axios
      .get(`/pages/${page_id}/instagram/profile`)
      .then(({ data }) => {
        commit('setProfile', data)
      })
  }

}